header {
  width: 100%;
  position: fixed;
  top: 0;
}

body {
  color: rgb(145, 144, 145);
  margin: 0;
  /* font-family: 'Avenir-Light', -apple-system, 'Avenir Next', Helvetica, Geneva, Tahoma, sans-serif; */
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.textBody {
  /* font-size: large; */
  /* font-family: 'Avenir-Light', -apple-system, 'Avenir Next', Helvetica, Geneva, Tahoma, sans-serif; */
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.logoDesktop {
  display: none;
}
.modal_details {
  color: rgb(145, 144, 145);
}

.youtube-video {
  aspect-ratio: 4 / 3;
  width: 50%;
  height: 600px;
}


.modal_body {
  /* font-size: larger; */
  /* font-family: 'Avenir-Light', -apple-system, 'Avenir Next', Helvetica, Geneva, Tahoma, sans-serif; */
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  margin: 20px;
  font-size: 20px;
}
.modal_body_Cast {
  /* font-size: larger; */
  font-family: 'Playfair Display', serif;
  /* font-family: 'Avenir-Light', -apple-system, 'Avenir Next', Helvetica, Geneva, Tahoma, sans-serif; */
  -webkit-font-smoothing: antialiased;
  margin: 30px;
  margin-bottom: 40px;
  font-size: 20px;
}

.modal_title {
  color: rgb(145, 144, 145);
  /* font-size: large; */
  /* font-family: 'Avenir-Light', -apple-system, 'Avenir Next', Helvetica, Geneva, Tahoma, sans-serif; */
  font-family: 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
}

.social {
  justify-content: center;
}

.spacingTitles {
  height: 100px;
}
.PageTitles {
  color: rgb(145, 144, 145);
  margin: 0;
  font-family: 'Playfair Display', serif;
  /* font-family: 'Avenir-Light', -apple-system, 'Avenir Next', Helvetica, Geneva, Tahoma, sans-serif; */
  -webkit-font-smoothing: antialiased;
}

.navbar {
  font-size: 1.525rem;
  width: 100%;
  height: 72px;
  /* background: linear-gradient(to bottom, cyan 0%, rgba(0,0,0,0) 100%); */
}

.hero {
  margin-top: 150px;
  filter: opacity(78%);
}

.navSpaceMobile {
  margin-left: 10px;
  height: 48px
}

.paraSize {
  height: 700px;
}

.navSpace {
  height: 72px
}

.flipImage {
  transform: scaleX(-1);
}


.bottomNavSpace {
  height: 32px
}

Form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
}
.fontFam {
  font-family: 'Playfair Display', serif;
}

/* .card{box-shadow: 0 5px 10px rgba(0,0,0,.2);} */



@media screen and (max-width: 991px) {
  /* .LogoMobile {
    display: none;
  } */

  .navMobileCenter {
    text-align: center;
    font-family: 'Playfair Display', serif;

  }

  .youtube-video {
    aspect-ratio: 4 / 3;
    height: 100%;
    width: 100%;
 
  }

  .logoDesktop {
    display: block;
    /* margin-bottom: -24px; */
  }

  /* .TitleStuff{
    font-size: 32px;
    }
    .paraSize{
      height: 500px;
    } */
  .navSpaceMobile {
    height: 64px;

  }

  .bottomNavSpace {
    height: 0px
  }

  .hero {
    margin-top: 72px;
  }
 
}